import React from "react"
import PropTypes from "prop-types"
import Box from "../Box/Box"
import RelatedPosts from "./RelatedPosts/RelatedPosts"

import "./PostContent.scss"
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs"

const PostContent = ({ title, content, relatedPosts: { latest } }) => (
  <div className="post__wrapper">
    <Box>
      <Breadcrumbs
        items={[{ name: "Blog", href: "/blog/" }, { name: title }]}
      />
    </Box>
    <Box>
      <div
        className="post__content"
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    </Box>
    <RelatedPosts posts={latest} />
  </div>
)

PostContent.propTypes = {
  content: PropTypes.string,
}

PostContent.defaultProps = {
  content: "Treść wpisu...",
}

export default PostContent
