import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

import "./Breadcrumbs.scss"

const Breadcrumbs = ({ items }) => {
  const schema = [
    {
      "@type": "ListItem",
      position: "1",
      name: "Home",
      item: `https://cssgenerator.pl/`,
    },
    ...items.map((item, index) => {
      if (index !== items.length - 1) {
        return {
          "@type": "ListItem",
          position: index + 2,
          name: item.name,
          item: `https://cssgenerator.pl${item.href}`,
        }
      } else {
        return {
          "@type": "ListItem",
          position: index + 2,
          name: item.name,
        }
      }
    }),
  ]
  console.log(JSON.stringify(schema))
  return (
    <>
      <Helmet>
        <script type="application/ld+json">{`
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": ${JSON.stringify(schema)}
    }
  `}</script>
      </Helmet>
      <div className="breadcrumbs">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          {items.map((item, index) => (
            <li key={item.name}>
              {index !== items.length - 1 ? (
                <Link to={item.href}>{item.name}</Link>
              ) : (
                item.name
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
}
Breadcrumbs.defaultProps = {
  items: [
    { name: "Właściwości CSS", href: "/wlasciwosci-css" },
    { name: "flex", href: "/wlasciwosci-css/flex" },
  ],
}

export default Breadcrumbs
