import React from "react"
import "./RelatedPosts.scss"
import Box from "../../Box/Box"
import RelatedItem from "./RelatedItem"

const RelatedPosts = ({ posts }) => {
  return (
    <div className="related__wrapper">
      <Box>
        <h3>Ostatnie wpisy</h3>
        <ul className="related__list">
          {posts.map((item, index) => (
            <RelatedItem key={index} data={item} />
          ))}
        </ul>
      </Box>
    </div>
  )
}

export default RelatedPosts
