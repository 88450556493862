import React from "react"
import { graphql } from "gatsby"
import PageHeader from "../components/PageHeader/PageHeader"
import PostContent from "../components/Blog/PostContent"
import Footer from "../components/Footer/Footer"
import Seo from "../components/Seo/Seo"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
  location,
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const posts = data.allMarkdownRemark.edges.filter(
    edge => !!edge.node.frontmatter.date
  )
  const { frontmatter, html } = markdownRemark
  return (
    <>
      <Seo
        title={`${frontmatter.title} | CSSGENERATOR.PL`}
        description={`Przeczytaj artykuł "${frontmatter.title}". Na blogu znajdziesz newsy ze świata IT i artykuły poradnikowe.`}
        location={location}
      />
      <PageHeader
        h1={frontmatter.title}
        desc={frontmatter.desc}
        date={frontmatter.date}
        notGenerator
      />
      <PostContent
        content={html}
        relatedPosts={{ latest: posts }}
        title={frontmatter.title}
      />
      <Footer />
    </>
  )
}
export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "D MMMM, YYYY", locale: "pl")
        slug
        title
        desc
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 5
    ) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "D MMMM, YYYY", locale: "pl")
            slug
            title
          }
        }
      }
    }
  }
`
