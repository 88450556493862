import React from "react"
import "./RelatedItem.scss"
import { Link } from "gatsby"
const RelatedItem = ({ data }) => {
  return (
    <li className="relatedItem">
      <div className="relatedItem__title">
        <Link to={data.node.frontmatter.slug}>
          {data.node.frontmatter.title}
        </Link>
      </div>
      <div className="relatedItem__date">{data.node.frontmatter.date}</div>
    </li>
  )
}

export default RelatedItem
